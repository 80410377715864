import NextImage from './NextImage';

export default function Loading() {
  return (
    <div className='flex min-h-screen items-center justify-center bg-typo-main'>
      <NextImage
        src='/loading/loading.gif'
        width={500}
        height={355}
        alt='loading'
      />
    </div>
  );
}
