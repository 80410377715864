'use client';

import { NextUIProvider } from '@nextui-org/react';
import {
  QueryClient,
  QueryClientProvider,
  QueryOptions,
} from '@tanstack/react-query';
import * as React from 'react';
import { Toaster } from 'react-hot-toast';

import Loading from '@/components/Loading';
import api from '@/lib/api';

const defaultQueryFn = async ({ queryKey }: QueryOptions) => {
  const { data } = await api.get(`${queryKey?.[0]}`);
  return data;
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <NextUIProvider>
        <Toaster position='top-center' />
        <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
      </NextUIProvider>
    </QueryClientProvider>
  );
}
